@use '@angular/material' as mat;

@import 'styles-variables';

@import '~bootstrap/scss/bootstrap-reboot';
@import '~bootstrap/scss/bootstrap-grid';
@import 'styles-reset';

@include mat.core();

@import './themes/default-theme';
@import './themes/light-theme';
@import './themes/black-theme';
@import './themes/nature-theme';

@import 'styles-reset.scss-theme';
@import './app/app/app.component.scss-theme';
@import './app/shared/big-input/big-input/big-input.component.scss-theme';

@mixin custom-components-theme($theme) {
    @include anms-styles-reset-theme($theme);
    @include anms-app-component-theme($theme);
    @include anms-big-input-component-theme($theme);
}

.default-theme {
    @include mat.all-component-themes($anms-theme);
    @include custom-components-theme($anms-theme);
}

// CUSTOM
.custom-dialog-container .mat-dialog-container {
    padding: 15px !important;
    overflow-x: hidden;
    overflow-y: hidden;
    //overflow-y: hidden;
    top: 10px;
}

.show-picture-dialog-container .mat-dialog-container {
    padding: 5px !important;
    overflow-x: hidden;
    overflow-y: auto;
    //overflow-y: hidden;
}

$primary-blue: #009fe3;
$primary-yellow: #fecb37;
$primary-green: #17723f;

$dark-pink: #9d4d98;

.primary-blue-background {
    color: white !important;
    background-color: $primary-blue !important;
}

.primary-yellow-background {
    color: white !important;
    background-color: $primary-yellow !important;
}

.primary-green-background {
    color: white !important;
    background-color: $primary-green !important;
}

.custom-dialog-container .mat-dialog-content {
    padding-left: 30px;
    padding-right: 30px;
    max-height: initial !important;
    height: calc(100% - 85px);
}

.mat-form-field-wrapper {
    padding-bottom: 0 !important;
}

.mat-dialog-actions {
    margin-top: -15px;
}

.image-responsive {
    width: 100%;
    height: auto;
}
.image-avatar {
    border-radius: 50%;
}

.mat-subheader {
    padding: 8px !important;
}

.empty-state {
    .title {
        font-weight: 200;
        font-size: 26px;
        margin-bottom: 26px;
    }
    .image {
    }
    .description {
        line-height: 48px;
        text-align: center;
        font-size: 16px;
    }
}

.big-counter-number {
    font-size: 28px;
}
.medium-counter-number {
    font-size: 24px;
}
.small-counter-number {
    font-size: 20px;
}

.big-counter-text {
    font-size: 13px;
    line-height: 14px;
    margin-top: 4px;
    font-stretch: condensed;
}

.list-counter-number {
    font-size: 22px;
}

.list-counter-text {
    font-size: 12px;
    font-stretch: extra-condensed;
}

.horizontal-button {
    background-color: #c8e6c9;
    border: 1px solid #899c89;
    padding-top: 8px;
    padding-bottom: 8px;
}

.hidden {
    display: none !important;
}

.sb-default .sb-wrapper {
    --sb-background: trasparent;
    --sb-color: gray;
    --sb-border-radius: 2px;
    --sb-overflow: hidden;
}

.sb-default .sb-icon {
    font-size: 24px !important;
}

/* custom scrollbar */
::-webkit-scrollbar {
    width: 14px;
}

::-webkit-scrollbar-track {
    background-color: transparent;
}

::-webkit-scrollbar-thumb {
    background-color: #d6dee1;
    border-radius: 20px;
    border: 5px solid transparent;
    background-clip: content-box;
}

::-webkit-scrollbar-thumb:hover {
    background-color: #a8bbbf;
}

.mat-slider-horizontal {
    height: 40px !important;
}
.mat-slider {
    padding: 0px !important;
}

.center {
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.small-text {
    color: gray;
    font-size: 11px;
    font-weight: normal;
    line-height: 10px !important;
}

#fab-button {
    position: fixed;
    bottom: 30px;
    z-index: 2000;
}

@media screen and (max-width: $screen-max-width) {
    #fab-button {
        right: 30px;
    }
}

@media screen and (min-width: $screen-max-width) {
    #fab-button {
        left: calc(100% / 2 + 290px);
    }
}

.mat-toolbar-row,
.mat-toolbar-single-row {
    height: 64px !important;
}

.activity-card {
    .mat-card-avatar {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .mat-card-right-icons {
        margin-top: -10px;
        margin-right: -8px;
    }

    mat-card-title {
        font-size: 16px;
    }
}
