@use '@angular/material' as mat;
$anms-primary: mat.define-palette(mat.$light-green-palette);
$anms-accent: mat.define-palette(mat.$green-palette);
$anms-warn: mat.define-palette(mat.$red-palette, 600);

$anms-theme: mat.define-light-theme($anms-primary, $anms-accent, $anms-warn);

$screen-max-width: 750px;

:root {
    --app-height: 100%;
}

html {
    margin: 0 auto;
    max-width: $screen-max-width;
}

body {
    padding: 0;
    margin: 0;
    overflow: hidden !important;
    height: 100vh;
    height: var(--app-height);
}

.toolbar {
    max-width: $screen-max-width !important;
}

.mat-toolbar-row,
.mat-toolbar-single-row {
    max-width: $screen-max-width;
}

.task-dialog-mobile {
    height: 0px;
    mat-dialog-container {
        > :first-child {
            display: flex;
            flex-direction: column;
            height: 100%;
        }
        mat-dialog-content,
        div[mat-dialog-content] {
            flex-grow: 1;
            max-height: unset;
        }
    }
}
