@use '@angular/material' as mat;

$toolbar-breakpoint: 1750px;

$fa-font-path: '~@fortawesome/fontawesome-free-webfonts/webfonts';

$link-hover-decoration: none;
$label-margin-bottom: 0;

$grid-breakpoints: (
    xs: 0,
    sm: 576px,
    md: 768px,
    lg: 992px,
    xl: 1200px
);

$success-colors: mat.define-palette(mat.$green-palette, 400);
$warning-colors: mat.define-palette(mat.$amber-palette, 400);
